body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

.app {
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.dashboard-block {
    margin-bottom: 40px;
    h1 {
        margin-bottom: 20px;
    }
}

.time-value {
    font-family: monospace;
    font-size: 15px;
}

.table {
    padding-right: 40px;
    thead {
        tr {
            th {
                text-align: left;
                padding-bottom: 10px;
                font-size: 22px;
                &.number-td {
                    text-align: right;
                }
            }
        }
    }
    tbody {
        tr {
            td {
                //
                &.chain-name {
                    font-weight: 500;
                    font-size: 18px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    svg {
                        margin-right: 7px;
                    }
                }
                text-align: left;
                padding-bottom: 7px;
                &.number-td {
                    text-align: right;
                }
            }
            &:hover {
                background-color: #f0f0f0;
            }
            &.total-row {
                td {
                    padding-top: 8px;
                    &.chain-name {
                        font-weight: bold;
                        font-size: 20px;
                    }
                }
            }
        }
    }
}
